// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; // Import Link
import TarotForm from "./components/TarotForm";
import Result from "./components/Result";
import Success from "./components/Success";

function App() {
  return (
    <Router>
      {/* Contenedor principal con fondo */}
      <div className="background-2">
        <div className="App">
          {/* Header con Logo y Enlace de Contacto */}
          <header className="app-header">
            {/* Contenedor del Logo */}
            <div className="logo-container">
              {/* Wrap the image with Link to make it clickable */}
              <Link to="/">
                <img
                  src="/image/base/tarot-realista-logo.png"
                  alt="Tarot Realista Logo"
                  className="logo"
                />
              </Link>
            </div>
            {/* Contenedor del Enlace de Contacto */}
            <div className="contact-container">
              <a href="mailto:info@autoflujo.com" className="contact-link">
                Contacto
              </a>
            </div>
          </header>

          {/* Definición de Rutas */}
          <Routes>
            <Route path="/" element={<TarotForm />} />
            <Route path="/success" element={<Success />} />
            <Route path="/result/:readingId" element={<Result />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
