// src/data/data.js
export const content = [
    {
      text: "Esta carta te invita a encontrar el equilibrio en tu vida. Todo fluye a tu favor cuando cuerpo, mente y espíritu están en sintonía. Es el momento de confiar en tu paz interior y dejar que guíe tus pasos. Mantente firme, pero flexible, y todo se alineará en perfecta armonía.",
      image: "/image/tarot_cards/1-armonia.png"
    },
    {
      text: "Esta carta te recuerda que tus sueños están al alcance de tus manos. Es el momento de transformar lo que imaginas en realidad. La dedicación y la fe en ti mismo son la clave para hacerlos posibles. No temas dar el primer paso, cada pequeña acción te acerca más a lo que deseas. Confía en el proceso, tus sueños están listos para hacerse realidad.",
      image: "/image/tarot_cards/2-suenos.png"
    },
    {
      text: "Esta carta simboliza la libertad y la independencia en tu trabajo y vida. Te recuerda que eres capaz de forjar tu propio camino, tomar decisiones por ti mismo y crear tus propias oportunidades. Con enfoque y creatividad, puedes alcanzar el éxito en cualquier entorno. La flexibilidad es tu mayor fortaleza; confía en tu habilidad para adaptarte y prosperar.",
      image: "/image/tarot_cards/3-freelance.png"
    },
    {
      text: "Esta carta simboliza el poder de la comunicación y la conexión con los demás. Te recuerda que tienes la capacidad de inspirar, motivar e impactar a aquellos que te rodean. Lo que compartes, ya sea en palabras o acciones, puede influir positivamente en la vida de los demás. Confía en tu autenticidad y en el impacto que puedes generar. Brilla con confianza, y el mundo seguirá tu ejemplo.",
      image: "/image/tarot_cards/4-influencer.png"
    },
    {
      text: "Esta carta simboliza tu fortaleza interna y tu capacidad de enfrentar desafíos con valentía. Te recuerda que, aunque el camino puede ser difícil, tienes la determinación necesaria para superar cualquier obstáculo. Es un llamado a no rendirse, a seguir adelante con convicción y a luchar por lo que es justo. Confía en tu poder y en la fuerza que te impulsa hacia adelante. La victoria está más cerca de lo que imaginas.",
      image: "/image/tarot_cards/5-lucha.png"
    },
    {
      text: "Esta carta representa el amor incondicional y el apoyo que proviene de los lazos familiares. Te recuerda que la verdadera fortaleza nace del cariño, la unión y el cuidado mutuo. Es un momento para valorar a quienes te rodean y nutrir esas relaciones con amor y dedicación. La familia, en cualquier forma que se manifieste, es tu refugio y tu fuerza.",
      image: "/image/tarot_cards/6-familia.png"
    },
    {
      text: "Esta carta simboliza la libertad de trabajar desde cualquier lugar, llevando tu vida profesional y personal a donde quieras. Te recuerda que las fronteras son solo líneas en un mapa, y que tienes el poder de crear tu propia realidad sin ataduras. Es un llamado a aprovechar la flexibilidad que te brinda la tecnología para vivir una vida plena y equilibrada, en sintonía con tu entorno. Confía en que puedes prosperar mientras sigues tu propio camino, adaptándote al cambio y disfrutando de la aventura.",
      image: "/image/tarot_cards/7-nomada.png"
    },
    {
      text: "Esta carta representa la búsqueda de nuevos horizontes y experiencias que enriquecen el alma. Es un recordatorio de que la vida está llena de aventuras esperando ser descubiertas, y cada viaje te acerca más a la comprensión de ti mismo y del mundo. La Viajera te invita a dejar atrás lo familiar y aventurarte con confianza hacia lo desconocido, sabiendo que el camino, más que el destino, es lo que te transformará.",
      image: "/image/tarot_cards/8-viajera.png"
    },
    {
      text: "Esta carta simboliza la capacidad de ser tú mismo, de moverte por la vida sin ataduras y con el viento a tu favor. Te invita a liberarte de las expectativas externas, de los miedos y las restricciones autoimpuestas. Es el momento de expandir tus alas y vivir auténticamente, abrazando tu poder personal. La libertad te recuerda que el verdadero viaje comienza cuando te permites ser plenamente tú, sin concesiones.",
      image: "/image/tarot_cards/9-libertad.png"
    },
    {
      text: "Esta carta simboliza la liberación de situaciones que ya no te sirven. Es un recordatorio de que, cuando sientes que algo te atrapa, tienes el poder de salir y encontrar un nuevo camino. Esta carta te invita a dejar atrás lo que te limita, a soltar viejas ataduras y a correr hacia una nueva libertad. Es el momento de actuar con valentía y romper con lo que te retiene, sabiendo que un futuro más brillante te espera al otro lado.",
      image: "/image/tarot_cards/10-escape.png"
    },
    {
      text: "Esta carta simboliza la valentía de tomar riesgos, crear oportunidades y construir tu propio camino. Te recuerda que el éxito viene de la determinación y la visión clara de lo que deseas lograr. La Emprendedora confía en su intuición, combina creatividad con estrategia, y avanza con decisión. Es un llamado a ser audaz y creer en tu capacidad de transformar ideas en realidades. El mundo está lleno de posibilidades, y tú tienes el poder de aprovecharlas.",
      image: "/image/tarot_cards/11-emprendedora.png"
    }
  ];
  