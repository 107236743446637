// src/components/Result.js

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../mystical-style.css';
import { content } from '../data/data';
import { FaTwitter, FaWhatsapp, FaLinkedin } from 'react-icons/fa';

const Result = () => {
  const { readingId } = useParams();
  const [tarotResult, setTarotResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedInfoCard, setSelectedInfoCard] = useState(null);

  const NextArrow = ({ onClick }) => {
    return (
      <button className="arrow arrow-right" onClick={onClick}>
        {">"}
      </button>
    );
  };
  
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="arrow arrow-left" onClick={onClick}>
        {"<"}
      </button>
    );
  };
  

  useEffect(() => {
    const fetchReading = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get_tarot_reading/${readingId}`);
        console.log('Reading fetched successfully!', response.data);
        if (response.status === 200) {
          setTarotResult(response.data);
          setSelectedCards(response.data.selected_cards);
          console.log('Mantra:', response.data.tarot_reading.mantra);
        }
      } catch (error) {
        console.error('Error fetching reading:', error);
        setErrorMessage('Problema de red. Verifica tu conexión.');
      }
    };
  
    fetchReading();
  }, [readingId]);
  

  // Shareable links
  const shareUrl = `${process.env.REACT_APP_CLIENT_BASE_URL}/result/${readingId}`;

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />, // Pass the custom next arrow
    prevArrow: <PrevArrow />, // Pass the custom previous arrow
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
          nextArrow: <NextArrow />, // Include custom arrows in mobile settings
          prevArrow: <PrevArrow />,
        }
      }
    ]
  };

  // Download handler
  const downloadMainTarotCard = async () => {
    try {
      const response = await fetch(tarotResult.tarot_card_image_path, { mode: 'cors' });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const filename = tarotResult.tarot_card_image_path.split('/').pop();
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the image:', error);
      alert('Error al descargar la imagen. Inténtalo de nuevo.');
    }
  };

  // Info card handlers
  const handleInfoCardClick = (cardIndex) => {
    setSelectedInfoCard(cardIndex);
  };

  const closeInfoCard = () => {
    setSelectedInfoCard(null);
  };

  // Error handling
  if (errorMessage) {
    return (
      <div className="error-container">
        <div className="error-message">
          <h2>Error</h2>
          <p>{errorMessage}</p>
          <Link to="/" className="back-link">Regresar al inicio</Link>
        </div>
      </div>
    );
  }

  // Loading state
  if (!tarotResult) {
    return (
      <div className="loading-container">
        <div className="loading-animation">
          <div className="icon">🔮</div>
          <div className="loading-text">
            <h2>Cargando tu lectura...</h2>
            <p>Por favor, espera un momento</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Title */}
      <div className="intro-tarot">
        <h1>Los Astros han hablado</h1>
      </div>

      {/* Tarot Header Section */}
      <div className="tarot-header-section">
        {/* Tarot Card and Description */}
        <div className="tarot-card-row">
          <div className="tarot-card-column">
            <img src={tarotResult.tarot_card_image_path} alt="Tarot Card" className="result-image" />
            <button className="download-button" onClick={downloadMainTarotCard}>Descargar Carta</button>
          </div>
          <div className="tarot-description-column">
            <h3>🃏 Tu Carta del Tarot</h3>
            <div className="divider"></div>
            <p className="tarot-description-p">{tarotResult.tarot_card_description}</p>
            {/* Tarot Info */}
            <div className="tarot-info-row">
              <div className="tarot-info-column">
                <h4>Números de la suerte</h4>
                <p className="lucky-numbers">{tarotResult.tarot_reading.lucky_numbers.join(" - ")}</p>
              </div>
              <div className="tarot-info-column">
                <h4>Color De Tu Aura</h4>
                <div className="aura-color-circle" style={{ backgroundColor: tarotResult.tarot_reading.aura_color_hex }}></div>
                <p className="aura-color-name">{tarotResult.tarot_reading.aura_color}</p>
              </div>
              <div className="tarot-info-column">
                <h4>Letra que Debes Buscar</h4>
                <p className="initial-letter">{tarotResult.tarot_reading.initial_letter}</p>
              </div>
            </div>
            {/* Shareable Links */}
            <div className="share-section">
              <p>Comparte:</p>
              <div className="share-buttons">
                <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button twitter">
                  <FaTwitter className="share-icon" /> Twitter
                </a>
                <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button whatsapp">
                  <FaWhatsapp className="share-icon" /> WhatsApp
                </a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA`)}&summary=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button linkedin">
                  <FaLinkedin className="share-icon" /> LinkedIn
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Tu Mantra */}
        {tarotResult.tarot_reading?.mantra ? (
          <div className="mantra-row">
            <h4>🕉️ Tu Mantra</h4>
            <p className="cards-description">Memorízalo y repítelo en esos momentos difíciles.</p>
            <p className="mantra">{tarotResult.tarot_reading.mantra}</p>
          </div>
        ) : (
          <p className="loading-text">Cargando tu mantra...</p>
        )}



        {/* Cards Section */}
        {selectedCards.length > 0 && (
          <div className="cards-section">
            <h2 className="cards-title">🎴 Tus 3 Cartas del Destino</h2>
            <p className="cards-description">Dale click en cada carta para conocer su significado.</p>
            <div className="cards-grid">
              {selectedCards.map(index => (
                <div key={index} className="card">
                  <img src={content[index].image} alt="Tarot Card" className="tarot-card-image" onClick={() => handleInfoCardClick(index)} style={{ cursor: 'pointer' }} />
                  {selectedInfoCard === index && (
                    <div className="info-card" onClick={(e) => e.stopPropagation()}>
                      <button className="close-info-card" onClick={closeInfoCard}>×</button>
                      <p>{content[index].text}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Carousel for mobile */}
            <div className="cards-carousel">
              <Slider {...sliderSettings}>
                {selectedCards.map(index => (
                  <div key={index} className="carousel-slide">
                    <div className="card">
                      <img src={content[index].image} alt="Tarot Card" className="tarot-card-image" onClick={() => handleInfoCardClick(index)} style={{ cursor: 'pointer' }} />
                      {selectedInfoCard === index && (
                        <div className="info-card" onClick={(e) => e.stopPropagation()}>
                          <button className="close-info-card" onClick={closeInfoCard}>×</button>
                          <p>{content[index].text}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}

        {/* Cosmic Trend Section */}
        {tarotResult.chart_path && (
          <div className="mantra-row">
            <h4>🌌 Tendencia Cósmica</h4>
            <p className="cards-description">Tus niveles de energía en el amor, dinero y salud en los próximos seis mesees.</p>
            <img src={tarotResult.chart_path} alt="Cosmic Chart" className="result-image" style={{ cursor: 'pointer' }} />
          </div>
        )}
      </div>

      {/* Main Results Content */}
      <div className="result-page">
        {/* Canción del Destino */}
        <div className="result-card">
          <h3>🪈 Canción del Destino</h3>
          <em>El universo te manda esta letra y melodía.</em>
          <div className="divider"></div>
          <p><strong>Canción:</strong> {tarotResult.song_recommendation.name}</p>
          <p><strong>Artista:</strong> {tarotResult.song_recommendation.artist.join(", ")}</p>
          <a href={tarotResult.song_recommendation.spotify_url} target="_blank" rel="noopener noreferrer">Escuchar en Spotify</a>
        </div>

        {/* Horizonte de Abundancia */}
        <div className="result-card">
          <h3>🔮 Horizonte de Abundancia</h3>
          <em>Hacia dónde debes dirigirte.</em>
          <div className="divider"></div>
          <p>Los astros de Google arrojan esta información:</p>
          <ul>
            {tarotResult.search_results.results.map((item, index) => (
              <li key={index}>
                <p><strong>{item.title}</strong></p>
                <p>{item.content}</p>
                <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
              </li>
            ))}
          </ul>
          <p><strong>Consejo místico:</strong> {tarotResult.mystical_advice}</p>
        </div>

        {/* Oráculo de tu Futuro Laboral */}
        <div className="result-card">
          <h3>🔭 Oráculo de tu Futuro Laboral</h3>
          <em>Qué le depara a tu profesión.</em>
          <div className="divider"></div>
          <ReactMarkdown>{tarotResult.career_advice}</ReactMarkdown>
        </div>

        {/* Predicción de tu salud */}
        <div className="result-card">
          <h3>🩻 ¡Tu Salud Corre Peligro!</h3>
          <em>Consejos para tu estilo de vida.</em>
          <div className="divider"></div>
          <ReactMarkdown>{tarotResult.health_advice}</ReactMarkdown>
        </div>

        {/* Predicción Cósmica de Dinero */}
        <div className="result-card">
          <h3>💸 Predicción Cósmica de Dinero</h3>
          <em>Tu cuenta bancaria en 3 años.</em>
          <div className="divider"></div>
          <ReactMarkdown>{tarotResult.money_prediction}</ReactMarkdown>
        </div>
      </div>

      {/* Luz de Mejora Personal */}
      <div className="tarot-header-section">
        <div className="result-card">
          <h3>🕯️ Luz de Mejora Personal</h3>
          <em>Habilidades que te ayudarán a salir adelante.</em>
          <div className="divider"></div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Habilidad/Conocimiento</th>
                  <th>Descripción</th>
                  <th>Nivel de Prioridad</th>
                </tr>
              </thead>
              <tbody>
                {tarotResult.practical_knowledge_table.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.skill}</td>
                    <td>{item.description}</td>
                    <td>{item.priority_level}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Shareable Links */}
      <div className="share-section">
        <p>Comparte:</p>
        <div className="share-buttons">
          <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button twitter">
            <FaTwitter className="share-icon" /> Twitter
          </a>
          <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button whatsapp">
            <FaWhatsapp className="share-icon" /> WhatsApp
          </a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA`)}&summary=${encodeURIComponent(`Éste es mi 🔮 Tarot Profesional hecho con IA: ${shareUrl}`)}`} target="_blank" rel="noopener noreferrer" className="share-button linkedin">
            <FaLinkedin className="share-icon" /> LinkedIn
          </a>
        </div>
      </div>

      {/* Privacy Notice */}
      <div className="privacy-notice">
        <p>Nota: Cualquiera con este enlace puede ver tu lectura. Comparte con precaución.</p>
      </div>
    </>
  );
};

export default Result;
