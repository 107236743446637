// TarotForms.js

import React, { useState } from "react";
import axios from "axios";
import '../mystical-style.css';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const TarotForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    birthDate: "",
    interests: "",
    profession: "",
    country: "",
    mainConcern: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Use environment variable for API base URL
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, formData);
      const { sessionId } = response.data;
  
      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Error creating checkout session:", error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Something else happened
        console.error("Error setting up request:", error.message);
      }
      setLoading(false);
    }
  };
  

  return (
    <div className="mystical-background">
      <div className="intro-tarot">
        <h1>Conoce tu Destino</h1>
          <p className="cards-description">
          Llena tu información y recibe un análisis realista de tu vida, basado en datos concretos y tendencias actuales, todo con un toque de sabiduría cósmica que solo este tarotista IA puede ofrecer.
          </p>
      </div>
      {!loading ? (
        <>
          <form onSubmit={handleSubmit}>
            <h3>Ingresa tu Información</h3>

            {/* Existing form fields */}
            <label htmlFor="firstName">Primer Nombre</label>
            <input
              type="text"
              name="firstName"
              placeholder="Primer Nombre"
              value={formData.firstName}
              onChange={handleChange}
              required
              disabled={loading}
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading}
            />

            <label htmlFor="birthDate">Fecha de Nacimiento</label>
            <input
              type="date"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              required
              disabled={loading}
            />

            <label htmlFor="interests">Intereses y hobbies</label>
            <textarea
              name="interests"
              placeholder="Intereses y hobbies"
              value={formData.interests}
              onChange={handleChange}
              required
              disabled={loading}
            ></textarea>

            <label htmlFor="profession">Profesión u ocupación actual</label>
            <input
              type="text"
              name="profession"
              placeholder="Profesión u ocupación actual"
              value={formData.profession}
              onChange={handleChange}
              required
              disabled={loading}
            />

            <label htmlFor="country">País de residencia</label>
            <input
              type="text"
              name="country"
              placeholder="País de residencia"
              value={formData.country}
              onChange={handleChange}
              required
              disabled={loading}
            />

            <label htmlFor="mainConcern">Preocupación principal o duda en tu vida</label>
            <textarea
              name="mainConcern"
              placeholder="Preocupación principal o duda en tu vida"
              value={formData.mainConcern}
              onChange={handleChange}
              required
              disabled={loading}
            ></textarea>

            <button type="submit" disabled={loading}>Ver tu futuro por 1 dólar</button>
            <p className="privacy-text">Tus datos están seguros con nosotros. No compartimos con terceros.</p>
          </form>

          {/* Nueva Sección: Descubre tu Suerte */}
          <section className="lucky-cards">
            <h2>Descubre tu Suerte</h2>
            <p>Con solo información básica, nuestro tarot IA elegirá tus cartas del destino</p>
            <img src="/image/base/cartas.png" alt="Cartas de Tarot" className="lucky-cards-image" />
          </section>

          {/* Nueva Sección: Tu Tarot con Datos Reales */}
          <section className="info-tarot">
            <h2>Tu Tarot con Datos Reales</h2>
            <p>
              Tu lectura contiene
            </p>
            <ul>
              <li>Tu carta de tarot personalizada</li>
              <li>Un mantra único para tu situación actual</li>
              <li>Consejos reales para tu futuro laboral</li>
              <li>Habilidades que debes adquirir para conseguir tus metas</li>
              <li>Recomendaciones para evitar problemas de salud comunes en tu profesión</li>
              <li>Y hasta una canción que el destino tiene para ti</li>
            </ul>
          </section>
                  {/* *** Nueva Sección: Seguridad y Confianza *** */}
        <section className="trust-section">
          <h2>Tus datos están seguros</h2>
          <p>Nos tomamos tu privacidad en serio. Tus datos están protegidos y nunca serán compartidos con terceros.</p>
          <p>¿Tienes dudas?<br></br><a href="mailto:info@autoflujo.com?subject=Duda%20sobre%20Tarot%20Realista%20IA">Envíanos un correo</a>.</p>
        </section>
        {/* *** Fin de la Nueva Sección *** */}
      </>
    ) : (
        // Display payment processing loading animation
        <div className="loading-container">
          <div className="loading-animation">
            <div className="icon">
              💳
            </div>
            <div className="loading-text">
              <h2>Redirigiendo al pago...</h2>
              <p>No cierres esta página</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TarotForm;
